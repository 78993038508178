"use client";

import { useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import Swal from "sweetalert2";
import { AlertService } from "@/services/api/Alert";
import useClientAuth from "@/hooks/useClientAuth";
import { IAlert } from "@/models";

export const StrategyAlertHandler = () => {
  const { selectedStrategy, selectedAccount } = useClientAuth();

  const fetchAlerts = async () => {
    // There are alerts only for strategies and not for accounts
    if (selectedAccount) {
      return;
    }
    try {
      const response = await AlertService.GetAlerts(Number(selectedStrategy));
      if (response.data.length > 0) {
        const alertsTriggered = response.data.filter((a) => a.triggered);
        const alertWithMaxAbsThreshold = _.maxBy(alertsTriggered, (a: IAlert) =>
          Math.abs(Number(a.threshold))
        );

        if (alertWithMaxAbsThreshold) {
          toast(alertWithMaxAbsThreshold.message, {
            style: {
              backgroundColor: alertWithMaxAbsThreshold.color,
              color: "white",
            },
            progressStyle: {
              background: "white",
            },
            delay: 5000,
            position: "bottom-right",
          });
        }
      }
    } catch (err: any) {
      Swal.fire("Error fetching alerts", err.message, "error");
    }
  };

  useEffect(() => {
    if (selectedStrategy && !selectedAccount) {
      fetchAlerts();
    }
  }, [selectedStrategy, selectedAccount]);

  return <></>;
};
