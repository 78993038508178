"use client";

import { useEffect, useState } from "react";
import { parseCookies } from "nookies";
import { ICredentials, setAuthHeader } from "@/services/api";

const useClientAuth = (isPnl: boolean = false) => {
  const [credentials, setCredentials] = useState<ICredentials>();

  const [selectedStrategy, setSelectedStrategy] = useState<unknown>();
  const [selectedAccount, setSelectedAccount] = useState<unknown>();

  useEffect(() => {
    const cookies = parseCookies();

    if (cookies.auth) {
      const _credentials = JSON.parse(atob(cookies.auth)) as ICredentials;
      setCredentials(_credentials);
      setAuthHeader(_credentials);

      const _selectedStrategy =
        cookies[isPnl ? "pnl-selected-strategy" : "selected-strategy"];
      const _selectedAccount =
        cookies[
          isPnl ? "pnl-selected-strategy-account" : "selected-strategy-account"
        ];
      if (_selectedStrategy) {
        setSelectedStrategy(_selectedStrategy);
      }
      if (_selectedAccount) {
        setSelectedAccount(_selectedAccount);
      }
    }
  }, []);

  return { credentials, selectedStrategy, selectedAccount };
};

export default useClientAuth;
